// project specific js -- require JS jQuery Kickstart: requirejs(['jquery'], function ($) {})
requirejs(['jquery'], function ($) {
  var sidebarMarginLeft;
  $("#service").click(function () {
    if ($(this).hasClass("inactive")) {
      console.log($(".sidebar-fid li.sidebar-item").css("marginLeft"));

      sidebarMarginLeft = $(".sidebar-fid li.sidebar-item").css("marginLeft");
      $(".sidebar-fid li.sidebar-item").css("display", "block");
      $(".sidebar-fid li.sidebar-item").css("marginLeft", 0);
      $("#service img").attr("src", "content/dam/${mandant}/individualisierung/internetneu_2/19_2/images/close.svg");
      $(this).removeClass("inactive");
    } else {
      $(".sidebar-fid li.sidebar-item").css("display", "none");
      $(".sidebar-fid li.sidebar-item").css("marginLeft", sidebarMarginLeft);
      $("#service img").attr("src", "https://www.vbessen-cappeln.de/content/dam/${mandant}/individualisierung/internetneu_2/19_2/images/kompass.svg");
      $(this).addClass("inactive");
    }
  });
})